<script setup lang="ts">
import { CfgCarousel } from '@cfgtech/ui'
import type { SbCarouselStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbCarouselStoryblok }>()

const images = computed(() => blok?.images?.map(item => item.value.filename) ?? [])
</script>

<template>
  <div class="mx-auto flex size-full max-w-screen-md flex-col items-stretch gap-y-6">
    <CfgCarousel
      autoplay
      class="relative size-full rounded-[24px] md:rounded-[40px]"
      :dots="true"
      :items="images"
      :options="{
        dragThreshold: 20,
      }"
      :partial-visibility="false"
    >
      <template #items="{ item, index }">
        <img
          :alt="blok?.images?.[index].value.alt ?? undefined"
          class="size-full h-[calc(100%-3rem)] rounded-[24px] object-cover md:rounded-[40px]"
          :src="item"
        >
      </template>

      <template #dot="{ scrollTo, selectedIndex }">
        <div class="absolute inset-x-0 bottom-0 flex w-full items-center justify-center gap-2">
          <button
            v-for="dot in images.length"
            :key="dot"
            class="aspect-square size-4 shrink-0 rounded-full border-2 border-brand transition-all"
            :class="{
              'scale-75 opacity-50': dot - 1 !== selectedIndex,
            }"
            data-allow-mismatch
            :disabled="dot - 1 === selectedIndex"
            @click="scrollTo(dot - 1)"
          />
        </div>
      </template>
    </CfgCarousel>
  </div>
</template>
